var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.$hasPermissions(_vm.clientSession, ["CLIENT_REPORTS"], 1)
      ? _c(
          "div",
          [
            _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
            _c(
              "div",
              {
                staticClass: "has-text-centered has-background-primary",
                staticStyle: { "margin-bottom": "20px" },
              },
              [
                _c(
                  "h1",
                  {
                    staticClass: "is-size-6 has-text-white",
                    staticStyle: { padding: "5px 0px" },
                  },
                  [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
                ),
              ]
            ),
            _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
              _c("div", { attrs: { id: "body-content-area" } }, [
                _c(
                  "div",
                  { class: { hidden: !_vm.isLoading } },
                  [_c("Loading")],
                  1
                ),
                _c("div", { class: { visible: _vm.isLoading } }, [
                  _c("div", { staticClass: "columns top-bar" }, [
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                        },
                        attrs: { id: "dateRangeSelector" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("label", [_vm._v("Report Selection Period:")]),
                            _vm._v("  "),
                            _vm.hideDateLabel
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": ".8em",
                                      color: "#808080",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.dates.currentDateRange.start
                                          )
                                        ) +
                                        " to " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.dates.currentDateRange.end
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("br"),
                            _c("kendo-dropdownlist", {
                              attrs: {
                                id: "ddlReportDateRange",
                                name: "ddlReportDateRange",
                                "data-source": _vm.reportDateRanges,
                                "data-text-field": "text",
                                "data-value-field": "value",
                              },
                              on: { select: _vm.onReportDateRangeSelect },
                              model: {
                                value: _vm.selectedReportDateRange,
                                callback: function ($$v) {
                                  _vm.selectedReportDateRange = $$v
                                },
                                expression: "selectedReportDateRange",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.selectedReportDateRange === "3",
                                expression: "selectedReportDateRange === '3'",
                              },
                            ],
                            attrs: { id: "dateRangePicker" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "inline-flex" } },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { margin: "0 20px" } },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "reportStartDate" } },
                                      [_vm._v("Start")]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("kendo-datepicker", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        id: "reportStartDate",
                                        name: "reportStartDate",
                                        required: "required",
                                        min: _vm.dates.start.minDate,
                                        max: _vm.dates.start.maxDate,
                                        format: "yyyy-MM-dd",
                                      },
                                      on: {
                                        change: _vm.onStartDateRangeChange,
                                      },
                                      model: {
                                        value: _vm.dates.currentDateRange.start,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dates.currentDateRange,
                                            "start",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dates.currentDateRange.start",
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "k-invalid-msg",
                                      attrs: { "data-for": "reportStartDate" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "reportEndDate" } },
                                      [_vm._v("End")]
                                    ),
                                    _c("br"),
                                    _c("kendo-datepicker", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        id: "reportEndDate",
                                        name: "reportEndDate",
                                        required: "required",
                                        max: _vm.dates.end.maxDate,
                                        format: "yyyy-MM-dd",
                                      },
                                      on: { change: _vm.onEndDateRangeChange },
                                      model: {
                                        value: _vm.dates.currentDateRange.end,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dates.currentDateRange,
                                            "end",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dates.currentDateRange.end",
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "k-invalid-msg",
                                      attrs: { "data-for": "reportEndDate" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "top-bar-spacer",
                      staticStyle: { height: "8px", width: "100%" },
                    },
                    [_vm._v(" ")]
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "control grid-button",
                        staticStyle: {
                          float: "right",
                          "margin-top": "-4.3rem",
                        },
                      },
                      [
                        _c("div", { attrs: { id: "buttongroup" } }, [
                          _c("div", { staticClass: "level" }, [
                            _c("div", { staticClass: "level-left" }, [
                              _c("div", { staticClass: "field is-grouped" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-accent",
                                      attrs: {
                                        disabled: !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["CLIENT_REPORTS"],
                                          2
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "NewReport",
                                            params: {},
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _vm._m(0),
                                      _c("span", [_vm._v(" Add Report ")]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-dark",
                                      attrs: {
                                        disabled:
                                          !_vm.hasSelection ||
                                          !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["CLIENT_REPORTS"],
                                            2
                                          ),
                                      },
                                      on: { click: _vm.confirmDelete },
                                    },
                                    [
                                      _vm._m(1),
                                      _c("span", [
                                        _vm._v(
                                          "Delete Report" +
                                            _vm._s(
                                              _vm.hasPluralSelection ? "s" : ""
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "control" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-light",
                                      on: { click: _vm.clearFilters },
                                    },
                                    [_c("span", [_vm._v("Clear Filters")])]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { ref: "divWrapper" },
                      [
                        _vm.maxHeight > 0
                          ? _c("Grid", {
                              style: { height: _vm.maxHeight + "px" },
                              attrs: {
                                filter: _vm.filter,
                                "data-items": _vm.institutionReports,
                                "selected-field": _vm.selectedField,
                                sortable: true,
                                sort: _vm.sort,
                                filterable: true,
                                pageable: Object.assign({}, _vm.pageable, {
                                  pageSizes: _vm.pageSizes,
                                }),
                                "page-size": _vm.pageSize,
                                skip: _vm.skip,
                                take: _vm.take,
                                total: _vm.totalRecords,
                                columns: _vm.columns,
                              },
                              on: {
                                pagechange: _vm.pageChangeHandler,
                                sortchange: _vm.sortChangeHandler,
                                filterchange: _vm.filterChangeHandler,
                                selectionchange: _vm.onSelectionChange,
                                headerselectionchange:
                                  _vm.onHeaderSelectionChange,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "editTemplate",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "bcc-color",
                                                attrs: {
                                                  to: {
                                                    name: "EditReport",
                                                    params: {
                                                      id:
                                                        "" + props.dataItem.id,
                                                      model:
                                                        "" +
                                                        JSON.stringify(
                                                          props.dataItem
                                                        ),
                                                      skip: "" + _vm.skip,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.dataItem.displayName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "downloadTemplate",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c(
                                          "td",
                                          { staticClass: "has-text-centered" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "download",
                                                attrs: {
                                                  href:
                                                    "" + props.dataItem.path,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-download",
                                                }),
                                              ]
                                            ),
                                            _vm._v("   "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "download",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.viewReport(
                                                      props.dataItem
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-eye",
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "reportTypeUploadDateTemplate",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .getUploadDate(
                                                    props.dataItem.uploadDate
                                                  )
                                                  .format("MM/DD/YYYY")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "reportYearOverrideCell",
                                    fn: function (ref) {
                                      var props = ref.props
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.parseYearOverride(
                                                  props.dataItem.yearOverride
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "reportTypeFilterSlotTemplate",
                                    fn: function (ref) {
                                      var props = ref.props
                                      var methods = ref.methods
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "k-filtercell" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "k-filtercell-wrapper",
                                              },
                                              [
                                                _c("input", {
                                                  staticClass: "k-textbox",
                                                  attrs: {
                                                    type: "text",
                                                    id: "" + props.field,
                                                  },
                                                  domProps: {
                                                    value: props.value,
                                                  },
                                                  on: {
                                                    input: function (ev) {
                                                      methods.change({
                                                        operator: "contains",
                                                        field: props.field,
                                                        value: ev.target.value,
                                                        syntheticEvent: ev,
                                                      })
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                609333049
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.showDeleteModal
                    ? _c(
                        "div",
                        {
                          staticClass: "modal is-active",
                          attrs: { id: "delete-modal" },
                        },
                        [
                          _c("div", {
                            staticClass: "modal-background",
                            on: {
                              click: function ($event) {
                                _vm.showDeleteModal = false
                              },
                            },
                          }),
                          _c("div", { staticClass: "modal-card" }, [
                            _c(
                              "header",
                              { staticClass: "modal-card-head has-bg-danger" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "modal-card-title has-bg-danger",
                                  },
                                  [_vm._v("Confirm Delete")]
                                ),
                                _c("a", {
                                  staticClass: "delete",
                                  attrs: { "aria-label": "close" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = false
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c("section", { staticClass: "modal-card-body" }, [
                              _vm._m(2),
                              _c("div", { staticClass: "field" }, [
                                _c("div", { staticClass: "control" }, [
                                  _c("input", {
                                    directives: [
                                      { name: "focus", rawName: "v-focus" },
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.confirmationText,
                                        expression: "confirmationText",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "input",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        "type `delete` and then click confirm",
                                    },
                                    domProps: { value: _vm.confirmationText },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        return _vm.deleteIfConfirmed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.confirmationText =
                                          $event.target.value.trim()
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c("footer", { staticClass: "modal-card-foot" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-danger",
                                  attrs: {
                                    disabled: _vm.confirmationText !== "delete",
                                  },
                                  on: { click: _vm.deleteIfConfirmed },
                                },
                                [_vm._m(3), _c("span", [_vm._v("Confirm")])]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      _vm.showDeleteModal = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
            _vm._m(4),
            _c("center", [
              _c("b", [
                _vm._v("You Lack The Permissions Required To View This Page"),
              ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _vm._v(' Type "'),
      _c("b", [_vm._v("delete")]),
      _vm._v('" to confirm that you want to delete one or more items. '),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "has-text-centered has-background-primary",
        staticStyle: { "margin-bottom": "20px" },
      },
      [
        _c(
          "h1",
          {
            staticClass: "is-size-6 has-text-white",
            staticStyle: { padding: "5px 0px" },
          },
          [_vm._v("No Permission")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }